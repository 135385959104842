import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import './Clientes.min.css'
import SliderClientes from '../SliderClientes/SliderClientes'

const Clientes = ({Anchor}) => {
  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className="flex-column parallax bg03">
      <Container className='d-flex flex-column contentClientes bd-highlight'>
          <div>
            <h1 className='text-white text-center'>Clientes</h1>
          </div>
          <div className='d-flex flex-grow-1 h-100 bd-highlight'>
          <Carousel className='text-white'>
                <Carousel.Item className='text-center my-5 p-1 carouselItem'>
                  <Card className='mb-3 mb-xl-0 m-auto pt-5 px-5 pb-4 shadow position-relative rounded-0 h-100'>
                    <Image src='./assets/imgs/clientes/carrera.jpg' roundedCircle={true} className='clientThumb position-absolute top-0 start-50 translate-middle shadow'/>
                    <p className='mt-3'>"Confitería Carrera ha contado con los servicios de Biotecnología Sur por mas de una década.
                    Particularmente en estos últimos dos años hemos trabajado codo a codo en procesos de actualización de practicas de elaboración, intensificando controles, diseñando y ejecutando planes de acción certeros que nos han permitido estar al día con los requisitos de los organismos de control y sobre todo estar tranquilos con nuestro trabajo. El proceso de mejora no termina y en ese camino es grato saber que contamos con el apoyo de del Dr. Fernández y su equipo."</p>
                    <p className='mb-0'><strong>Álvaro Rodríguez | Gerente General</strong></p>
                  </Card>
                </Carousel.Item>
                <Carousel.Item className='text-center my-5 p-1 carouselItem'>
                  <Card className='mb-3 mb-xl-0 m-auto pt-5 px-5 pb-4 shadow position-relative rounded-0'>
                    <Image src='./assets/imgs/clientes/lasgaviotas.jpg' roundedCircle={true} className='clientThumb position-absolute top-0 start-50 translate-middle shadow'/>
                    <p className='mt-3'>"En Las Gaviotas contamos con el asesoramiento técnico de Biotecnologiasur ya hace más de 10 años, gracias a este asesoramiento hemos incorporado y mejorado  procesos de elaboración, capacitado a nuestro personal y también estar informado de las ultimas actualizaciones de las normativas bromatológicas. Agradecemos al  Dr. Fernández y demás técnicos ya que contar con su servicios y asesoramiento continuo nos brinda tranquilidad."</p>
                    <p className='mb-0'><strong>Cinthya Macchiavelo | Gerente</strong></p>
                  </Card>
                </Carousel.Item>
                <Carousel.Item className='text-center my-5 p-1 carouselItem'>
                  <Card className='mb-3 mb-xl-0 m-auto pt-5 px-5 pb-4 shadow position-relative rounded-0'>
                    <Image src='./assets/imgs/clientes/cufp.jpg' roundedCircle={true} className='clientThumb position-absolute top-0 start-50 translate-middle shadow'/>
                    <p className='mt-3'>"La Cámara Uruguaya de Fabricantes de Pasta cuenta desde hace años con el apoyo de Biotecnologiasur y su equipo en todo lo referente en asesoramiento, controles, capacitaciones y trámites para nuestros afiliados. El Dr. Jorge Fernández, nos ha acompañado en gestiones en el M.S.P, en el S.R.A de la I.M. y ahora está autorizado por I.N.E.F.O.P para realizar los cursos de Manipulación de Alimentos con importantes ventajas para los asociados. Por lo expuesto seguiremos contando con los servicios de Biotecnologiasur y su equipo."</p>
                    <p className='mb-0'><strong>Gustavo Capalvo | Presidente</strong></p>
                  </Card>
                </Carousel.Item>
                <Carousel.Item className='text-center my-5 p-1 carouselItem'>
                  <Card className='w-md-75 mb-3 mb-xl-0 m-auto pt-5 px-5 pb-4 shadow position-relative rounded-0'>
                    <Image src='./assets/imgs/clientes/cambadu.jpg' roundedCircle={true} className='clientThumb position-absolute top-0 start-50 translate-middle shadow'/>
                    <p className='mt-3'>"Durante el año 2015 y a partir de la solicitud de la Asociación de Comerciantes de Rivera a través de su Programa “Promoviendo el Desarrollo Socio-Económico Local Mejorando la Valorización y Aprovechamiento de los Recursos Turísticos Departamentales y Regionales” BID-FOMIN, CAMBADU a través de su Unidad de Desarrollo Empresarial, ejecutó exitosamente con el equipo de Biotecnologíasur encabezado por el Dr. Jorge Fernández una Consultoría en Auditoría, Validación de acciones correctivas  y seguimiento en la implementación de programas en Buenas Prácticas de Manufactura en 5 empresas de la localidad de Rivera, así como en el traspaso del conocimiento teórico de la herramienta a los técnicos de ACIR por parte del equipo de Biotecnologíasur."</p>
                    <p className='mb-0'><strong>Cambadu | Unidad de Desarrollo Empresarial</strong></p>
                  </Card>
                </Carousel.Item>
              </Carousel>
          </div>
      </Container>
      <div className='bg-white shadow w-100'>
        <Container>
          <SliderClientes/>
        </Container>
      </div>
    </section>
    </>
  )
}
export default Clientes
