import {React, useState, useEffect} from 'react'
import './NavBar.min.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'

const NavBar = ({Anchors}) => {

    const [NavStyle, setNavStyle] = useState('style1')
 
    const controlNavBar = () => {
        let scrollTop = window.pageYOffset
        

        if(scrollTop > 95){
            setNavStyle('style2')
        }else{
            setNavStyle('style1')
        }
    }

    useEffect(() => {
      window.addEventListener('scroll', controlNavBar)
        return () => {
        window.removeEventListener('scroll', controlNavBar)
      }
    }, [])
    
    
    return (
        <Navbar id="navbar" expand="lg" fixed="top" className={ NavStyle === 'style1'  ? 'NavBar' : 'NavBar navbar-white'}>
            <Container>
                <Navbar.Brand href="#">
                    <div className={ NavStyle === 'style1'  ? 'd-block' : 'd-none'}><img src='./assets/imgs/logo-bts-light.png' alt=""/></div>
                    <div className={ NavStyle === 'style1'  ? 'd-none' : 'd-block'}><img src='./assets/imgs/logo-bts-dark.png' alt=""/></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href={ `#${Anchors[0]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}>Quiénes Somos</Nav.Link>
                        <Nav.Link href={ `#${Anchors[1]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}>Servicios</Nav.Link>
                        <Nav.Link href={ `#${Anchors[2]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}>Cursos</Nav.Link>
                        <Nav.Link href={ `#${Anchors[3]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'}active={false}>Clientes</Nav.Link>
                        <Nav.Link href={ `#${Anchors[4]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}>Novedades</Nav.Link>
                        <Nav.Link href={ `#${Anchors[5]}` } className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}>Contacto</Nav.Link>
                        <div class="nav-socials">
                            <Nav.Link href="https://wa.me/+59891047350" target="_blank" className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}><i class="bi bi-whatsapp"></i></Nav.Link>
                            <Nav.Link href="https://www.instagram.com/biotecnologiasur/" target="_blank" className={ NavStyle === 'style1'  ? 'text-uppercase' : 'text-uppercase nav-link-dark'} active={false}><i class="bi bi-instagram"></i></Nav.Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar