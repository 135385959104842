import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import './Cursos.min.css'

const Cursos = ({Anchor}) => {
  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className='section-cursos' fluid>
        <Container>
            <div>
            <Row>
                <h1 className='text-center text-md-start'>Cursos</h1>
            </Row>
            <Row xs={1} md={2} lg={2} className="g-4">
              <Col>
                <Card className='h-100 rounded-0 border-0 shadow-sm position-relative text-white'>
                  <Card.Body className='pt-5 px-4 position-relative'>
                    <div className='ribon-wrapper'>
                    <Badge bsPrefix='badge ribon' bg='white' className='rounded-0 shadow-sm position-absolute top-0 start-0 mt-3'><h6>Curso Teórico Práctico</h6></Badge>
                    </div>
                    <Card.Title className='mt-4 mb-4 w-lg-75 ms-auto me-auto text-uppercase text-center'>Capacitación en Inocuidad de Alimentos y manejo de los mismos</Card.Title>
                    <Badge pill bsPrefix='badge ribon-level' bg="" className='p-2 px-3 position-absolute top-100 start-50 translate-middle'>
                      Nivel: Operario
                    </Badge>
                  </Card.Body>
                  <Card.Body className='card-body-alt p-4 text-center'>
                    <Card.Text className='mb-4 mt-3'>
                      Para personas que trabajan o aspiran a trabajar en la elaboración, manipulación, fraccionamiento, industrialización, almacenamiento, transporte y comercialización de alimentos a nivel industrial, artesanal o gastronómico.
                    </Card.Text>
                    <Button variant="outline-light" className='rounded-0' href='./assets/docs/Manipulador_de_alimentos-Operario-Programa.pdf' target='_blank'>Más Info</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className='h-100 rounded-0 border-0 shadow-sm position-relative text-white'>
                  <Card.Body className='pt-5 px-4 position-relative'>
                    <div className='ribon-wrapper'>
                    <Badge bsPrefix='badge ribon' bg='white' className='rounded-0 shadow-sm position-absolute top-0 start-0 mt-3'><h6>Curso Teórico Práctico</h6></Badge>
                    </div>
                    <Card.Title className='mt-4 mb-4 w-lg-75 ms-auto me-auto text-uppercase text-center'>Capacitación en Inocuidad de Alimentos y manejo de los mismos</Card.Title>
                    <Badge pill bsPrefix='badge ribon-level' bg="" className='p-2 px-3 position-absolute top-100 start-50 translate-middle'>
                      Nivel: Decisor
                    </Badge>
                  </Card.Body>
                  <Card.Body className='card-body-alt p-4 text-center'>
                    <Card.Text className='mb-4 mt-3'>
                    Orientado para nivel gerencial y/o jefaturas de plantas en empresas del área alimentaria, en todas las etapas de la cadena de producción industrialización, almacenamiento, transformación, fraccionamiento, transporte y comercialización.
                    </Card.Text>
                    <Button variant="outline-light" className='rounded-0' href='./assets/docs/Manipulador_de_alimentos-Decisor-Programa.pdf' target='_blank'>Más Info</Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            </div>
        </Container>
    </section>
    </>
  )
}

export default Cursos