import { useState } from 'react'
import {infoModals} from './infoModals.js'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './Servicios.min.css'

const Servicios = ({Anchor}) => {

  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState({})

  function handleShow(modal) {
    setModalContent(infoModals[modal])
    setShow(true);
  }

  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className="bg02" fluid>
        <Container>
            <div className='w-100'>
            <Row>
                <h1 className='text-white text-center text-md-start'>Servicios</h1>
            </Row>
            <div className='cardsServicios'>
                <Row className='g-4' xs={1} md={2}>
                    <Col>
                        <Card className="text-white" onClick={() => handleShow(0)}>
                        <div className='text-center text-uppercase'>
                            <Card.Title>{infoModals[0].titulo}</Card.Title>
                            <Button className='rounded-0' variant="outline-light">Más Info</Button>
                        </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-white" onClick={() => handleShow(1)}>
                        <div className='text-center text-uppercase'>
                            <Card.Title>{infoModals[1].titulo}</Card.Title>
                            <Button className='rounded-0' variant="outline-light">Más Info</Button>
                        </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-white" onClick={() => handleShow(2)}>
                        <div className='text-center text-uppercase'>
                            <Card.Title>{infoModals[2].titulo}</Card.Title>
                            <Button className='rounded-0' variant="outline-light">Más Info</Button>
                        </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-white" onClick={() => handleShow(3)}>
                        <div className='text-center text-uppercase'>
                            <Card.Title>{infoModals[3].titulo}</Card.Title>
                            <Button className='rounded-0' variant="outline-light">Más Info</Button>
                        </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            </div>            
        </Container>
    </section>
    <Modal show={show}  size="lg" onHide={() => setShow(false)} centered>
        <Modal.Header className='p-4' closeButton>
            <Modal.Title className='text-uppercase'>{modalContent.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>{modalContent.contenido}</Modal.Body>
        {modalContent.footer && <Modal.Footer className='box-descarga'>{modalContent.footer}</Modal.Footer>}
    </Modal>
    </>
  )
}

export default Servicios