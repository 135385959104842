import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import './QuienesSomos.css'

const QuienesSomos = ({Anchor}) => {
  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className="bg01" fluid>
        <Container>
            <Row>
                <Col xs="8" lg="5" xl="6" className='offset-2 offset-lg-0 mb-5 mt-4 text-center pe-lg-5'> 
                  <Image src="./assets/imgs/quienes-somos.png" fluid/>
                </Col>
                <Col sm="12" lg="7" xl="6" className="paragraph">
                    <h1 className='text-center text-md-start'>Quiénes Somos</h1>
                    <p>Biotecnología Sur es una empresa uruguaya dedicada al Asesoramiento Integral de la Industria Alimentaria desde hace más de tres décadas. Ofrecemos nuestra vasta experiencia en Consultoría, Capacitación y Análisis Microbiológicos de Alimentos.</p>
                    <p>En el área de Consultoría realizamos <strong>Análisis y Diagnósticos de Situación</strong> que permiten detectar los puntos débiles y fortalezas en los procesos de elaboración, la implementación y aplicación de buenas prácticas de manufactura y procedimientos de limpieza y desinfección, y todos los criterios tendientes a asegurar la inocuidad de los productos elaborados. A partir de los resultados obtenidos elaboramos un informe y un <strong>Plan de Acción</strong> a seguir.</p>
                    <p>Brindamos <strong>Capacitación</strong> en lo que se refiere Buenas <strong>P</strong>rácticas de <strong>M</strong>anufacturas, <strong>P</strong>rocedimientos <strong>O</strong>perativos <strong>E</strong>standarizados de <strong>S</strong>anitización y <strong>HACCP</strong>. Los mismos se dictan para Operarios, Mandos Medios y Altos de la empresa. Entendemos que la buena implementación de estos Manuales es imprescindible para la obtención de buenos resultados e información de valor para la toma de decisiones.</p>
                    <p>Ofrecemos una gran variedad de <strong>Análisis Microbiológicos</strong> permitiendo a nuestros clientes llevar un preciso control de los productos que desarrollan.</p>
                    <p className='m-0'>Todo lo mencionado permite brindar servicios que se adapten a la realidad de cada empresa con una visión integral de la misma.</p>
                </Col>
            </Row>
            
        </Container>
    </section>
    </>
  )
}

export default QuienesSomos