import React from 'react'
import './SliderClientes.min.css'

const SliderClientes = () => {
  return (
    <div className="slider">
        <div className="slide-track">
            <div className="logo"><img src="./assets/imgs/clientes/banil.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cambadu.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/carrera.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cativelli.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cufp.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/devoto.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/inefop.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/lasgaviotas.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/hotel-del-prado.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/la-calizzanesa.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/los-dos-leones.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/naturalezas.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/oriental.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/pellicer.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/roberto-barcos.jpg" alt="" /></div>
            {/* repite */}
            <div className="logo"><img src="./assets/imgs/clientes/banil.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cambadu.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/carrera.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cativelli.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/cufp.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/devoto.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/inefop.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/lasgaviotas.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/hotel-del-prado.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/la-calizzanesa.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/los-dos-leones.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/naturalezas.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/oriental.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/pellicer.jpg" alt="" /></div>
            <div className="logo"><img src="./assets/imgs/clientes/roberto-barcos.jpg" alt="" /></div>



            
        </div>
    </div>
  )
}

export default SliderClientes