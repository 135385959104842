import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export const infoModals = [
    {titulo:'Asesoramiento Técnico y Consultoría', 
    contenido: 
    <div><p className='pb-4 text-center text-md-start'>Trabajamos con la industria alimentaria y gastronómica desde 1991, ofreciendo asesoramiento técnico en sistemas de autocontrol (BPM, POES, HACCP) específicos y a medida para cada empresa, así como establecer las condiciones que permitan acceder a certificaciones de las Normas ISO 9001 (Gestión de Calidad) y 22000 (Gestión de Inocuidad). Redacción de manuales, implementación de los sistemas puesta en funcionamiento y monitoreo, con la correspondiente capacitación del personal.</p> 
    <h5 className='text-center text-uppercase pb-4'>Nuestros servicios</h5>
    <ul className='listado-servicios'>
        <li>Aseguramiento de la Inocuidad de los productos elaborados por la empresa</li>
        <li>Capacitación de personal en manipulación de alimentos</li>
        <li>Implementación de Procedimientos Operativos Estandarizados de Sanitización</li>
        <li>Implementación de Buenas Prácticas de Manufactura</li>
        <li>Asesoramiento en Gestión de Proveedores</li>
        <li>Asesoramiento en Gestión de Residuos</li>
        <li>Asesoramiento en el Manejo Integrado de Plagas</li>
        <li>Registro de productos ante las autoridades competentes (Intendendencias departamentales, MGAP; MSP, etc.)</li>
        <li>Habilitaciones, renovación de habilitaciones y ampliaciones de giros</li>
        <li>Representación técnica ante organismos públicos (IM, IMC, MSP, MGAP)</li>
    </ul>
    </div>
    },
    {titulo:'Capacitación', 
    contenido:
    <div className='text-center text-md-start'>
        <div className='pb-4'>
            <h5 className='text-uppercase'>Manipuladores de alimentos</h5>
            <p>Cursos presenciales en la propia empresa o en nuestras instalaciones. En modalidad audiovisual, de acuerdo a los requisitos para los niveles de operario y decisor, siguiendo los programas de la Intendencia de Montevideo con materiales de apoyo de elaboración propia específicos para cada sector de la industria alimentaria o gastronómica.</p>
        </div>
        <div className='pb-4'>
            <h5 className='text-uppercase'>Personal de limpieza</h5>
            <p>Cursos presenciales teórico prácticos en la propia empresa sobre Procedimientos Operativos Estandarizados de Limpieza y Desinfección.</p>
        </div>
        <div className='pb-4'>
            <h5 className='text-uppercase'>Personal de ventas y atención al público</h5>
            <p>Cursos presenciales teórico prácticos en la propia empresa sobre buenas prácticas de atención al público.</p>
        </div>
        <div className='text-center pb-3'>
        <div className='d-flex justify-content-center'>
            <p className='alert alert-info text-center border-0 rounded-0'>Todos estos cursos también se ofrecen en modalidad online en forma simultánea o asincrónica.</p>
        </div>
        </div>
    </div>,
    footer:
    <p className='text-center'><small>Nuestra empresa se encuentra registrada como Entidad Capacitadora en INEFOP con el número 2699.</small></p>
    },
    {titulo:'Análisis Microbiológicos', 
    contenido: 
    <div>
        <h4 className='text-center mb-lg-4 text-uppercase'>Alimentos, Superficie, Agua, Ambiente.</h4>
        <h5 className='text-uppercase text-center mb-3'>Alimentos</h5>
        <Row xs={1} lg={3} className='mb-lg-5'>
            <Col>
            <ul className='mb-0 listado-servicios'>
                <li>Aerobios</li>
                <li>E coli</li>
                <li>Enterobacterias</li>
                <li>Bacillus cereus</li>
                <li>Coliformes totales</li>
            </ul>
            </Col>
            <Col>
            <ul className='mb-0 listado-servicios'>
                <li>Salmonella spp.</li>
                <li>Pseudomona spp.</li>
                <li>Listeria spp.</li>
                <li>Anaerobios esporulados</li>
                <li>Estafilococos coagulasa (+)</li>
            </ul>
            </Col>
            <Col>
            <ul className='listado-servicios'>
                <li>Bacterias Ácido Lácticas</li>
                <li>Pseudomona aeruginosa</li>
                <li>Listeria monocytogenes</li>
                <li>Hongos y Levaduras</li>
            </ul>
            </Col>
        </Row>
        <Row xs={1} lg={3}>
            <Col>
                <h5 className='text-uppercase text-center mb-3'>Superficie</h5>
                <ul className='listado-servicios'>
                    <li>Aerobios totales</li>
                    <li>Pseudomona spp.</li>
                    <li>Listeria spp.</li>
                    <li>Coliformes totales</li>
                    <li>Hongos y Levaduras</li>
                </ul>
            </Col>
            <Col>
                <h5 className='text-uppercase text-center mb-3'>Ambiente</h5>
                <ul className='listado-servicios'>
                    <li>Aerobios totales</li>
                    <li>Hongos y Levaduras</li>
                </ul>
            </Col>
            <Col>
                <h5 className='text-uppercase text-center mb-3'>Agua</h5>
                <ul className='listado-servicios'>
                    <li>Bacterias heterótrofas a 35°C</li>
                    <li>Coliformes totales</li>
                    <li>E coli</li>
                    <li>Pseudomona aeruginosa.</li>
                </ul>
            </Col>
        </Row>
    </div>,
    footer:
    <div className='d-flex align-items-center flex-column flex-lg-row'>
        <p className='mb-3 text-center mb-lg-0 text-lg-start flex-grow-1'>Descargue el formulario de solicitud de entrega de muestras para análisis</p>
        <Button className='btn-dark' href='./assets/docs/Solicitud_de_analisis_SA-V1-PDF-EDIT-Formato.pdf' target='_blank'>Descargar</Button>
    </div>
    },
    {titulo:'Otros estudios y determinaciones', 
    contenido: 
    <div>
        <Row xs={1} lg={2}>
            <Col>
            <div className='mb-4'>
            <h5 className='text-uppercase text-center mb-3'>Análisis Físico-Químicos de agua.</h5>
            <ul className='listado-servicios'>
                <li>Color, conductividad, turbiedad, olor, sabor pH.</li>
                <li>Dureza total, hierro, nitratos, nitritos, sodio, sólidos disueltos, sulfatos.</li>
            </ul>
            </div>
            <div className='mb-4'>
            <h5 className='text-uppercase text-center mb-3'>Estudios de vida útil</h5>
            <ul className='listado-servicios'>
                <li>Tiempo real</li>
                <li>Acelerado</li>
                <li>Estabilidad</li>
            </ul>
            </div>
            <div>
            <h5 className='text-uppercase text-center mb-3'>Composición Centesimal</h5>
            <ul className='listado-servicios'>
                <li>Tabla de Información nutricional</li>
            </ul>
            </div>  
            </Col>
            <Col>
            <h5 className='text-uppercase text-center mb-3'>Otras determinaciones en alimentos</h5>
            <ul className='listado-servicios'>
                <li>Humedad</li>
                <li>pH</li>
                <li>Actividad de agua (aw)</li>
                <li>Evaluación sensorial</li>
                <li>Grasas totales.</li>
                <li>Grasas en base seca</li>
            </ul>
            </Col>
        </Row>
    </div>,
    footer:
    <div className='d-flex align-items-center flex-column flex-lg-row'>
        <p className='mb-3 text-center mb-lg-0 text-lg-start flex-grow-1'>Descargue el formulario de solicitud de entrega de muestras para análisis</p>
        <Button className='btn-dark' href='./assets/docs/Solicitud_de_analisis_SA-V1-PDF-EDIT-Formato.pdf' target='_blank'>Descargar</Button>
    </div>
    },
  ]