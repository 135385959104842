import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import './Novedades.min.css'

const Novedades = ({Anchor}) => {
  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className='section-novedades bg01' fluid>
          <Container>
            <div>
              <Row>
                  <h1 className='text-center text-md-start'>Novedades</h1>
              </Row>
              <Row xs={1} className="g-4">
                <Col>
                  <Card className='border-0 shadow-sm rounded-0 p-3 pt-4 position-relative'>
                    <Card.Body>
                      <Badge bg='' className='position-absolute top-0 start-3 border-0 shadow-sm rounded-0 py-2 px-3'>28 de julio de 2022</Badge>
                      <div className='card-content d-flex flex-column flex-lg-row align-items-center'>
                        <div className='flex-grow-1'>
                          <h5 className='mt-2'>Nueva Reglamentación</h5>
                          <p>Documentación obligatoria para habilitación de empresas alimentarias.</p>
                        </div>
                        <Button className='btn-dark mt-4 mt-lg-0 ms-lg-5 me-lg-3' href='./assets/docs/Documentacion-obligatoria-para-habilitacion-de-empresas-alimentarias-primeravez.pdf' target='_blank'>Descargar</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className='border-0 shadow-sm rounded-0 p-3 pt-4 position-relative'>
                    <Card.Body>
                      <Badge bg='' className='position-absolute top-0 start-3 border-0 shadow-sm rounded-0 py-2 px-3'>22 de julio de 2022</Badge>
                      <div className='card-content d-flex flex-column flex-lg-row align-items-center'>
                        <div className='flex-grow-1'>
                          <h5 className='mt-2'>Nueva Reglamentación</h5>
                          <p>Prorrogar la vigencia de la habilitación y registro de productos otorgados por el Servicio de Regulación Alimentaria por el término de 18 (dieciocho) meses, a partir del vencimiento del registro concedido.</p>
                        </div>
                        <Button className='btn-dark mt-4 mt-lg-0 ms-lg-5 me-lg-3' href='./assets/docs/2941-22-prorroga-18-meses.pdf' target='_blank'>Descargar</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className='border-0 shadow-sm rounded-0 p-3 pt-4 position-relative'>
                    <Card.Body>
                      <Badge bg='' className='position-absolute top-0 start-3 border-0 shadow-sm rounded-0 py-2 px-3'>24 de mayo de 2022</Badge>
                      <div className='card-content d-flex flex-column flex-lg-row align-items-center'>
                        <div className='flex-grow-1'>
                          <h5 className='mt-2'>Nueva Reglamentación</h5>
                          <p>La I.M. introduce nuevos cambios, Resolución Nº 0149/22, en lo que se refiere a la Habilitación de Locales del S.R.A., donde si se cumple con determinados puntos no será necesario la renovación de la Habilitación, ya que la misma no tendrá vencimiento. Puntos importantes Art. 8 al Art. 11.</p>
                        </div>
                        <Button className='btn-dark mt-4 mt-lg-0 ms-lg-5 me-lg-3' href='./assets/docs/0149-22-Habilitacion-de-locales-Pago-unico-8-al-11.pdf' target='_blank'>Descargar</Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </div>
          </Container>
    </section>
    </>
  )
}
export default Novedades