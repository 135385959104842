import NavBar from "./Components/NavBar/NavBar";
import Header from "./Components/Header/Header";
import QuienesSomos from "./Components/QuienesSomos/QuienesSomos";
import Servicios from "./Components/Servicios/Servicios";
import Cursos from "./Components/Cursos/Cursos";
import Clientes from "./Components/Clientes/Clientes";
import Novedades from "./Components/Novedades/Novedades";
import Contacto from "./Components/Contacto/Contacto";



function App() {

  const Anchors = ['QuienesSomos', 'Servicios', 'Cursos', 'Clientes', 'Novedades', 'Contacto'] 

  return (
    <>
      <NavBar Anchors={Anchors}/>
      <Header/>
      <QuienesSomos Anchor={ Anchors[0] }/>
      <Servicios Anchor={ Anchors[1] }/>
      <Cursos Anchor={ Anchors[2] }/>
      <Clientes Anchor={ Anchors[3] }/>
      <Novedades Anchor={ Anchors[4] }/>
      <Contacto Anchor={ Anchors[5]}/>
    </>
  );
}

export default App;
