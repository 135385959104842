import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './Header.min.css'

const Header = () => {
  return (
    <div className="position-relative overflow-hidden">
        <section className="sectionVideo p-0 position-absolute vh-100 vw-100">
            <div className='vh-100 vw-100 overflow-hidden position-absolute'>
                <div className='position-absolute top-0 start-0 vw-100 vh-100 d-block cover'></div>
            </div> 
            <video src="./assets/BTS-Opening-Video.mp4" type="video/mp4" autoPlay={true} muted loop id="video"></video>
        </section>
        <Carousel className='vh-100' indicators={false} controls={false}>
            <Carousel.Item className='vh-100 position-relative'>
                <div className='position-absolute top-50 start-50 translate-middle text-white text-center headerCaption'>
                    <h1 className='text-uppercase text-white'>Bienvenido</h1>
                </div>
            </Carousel.Item>
            <Carousel.Item className='vh-100'>
                <div className='position-absolute top-50 start-50 translate-middle text-white text-center headerCaption'>
                    <h1 className='text-uppercase text-white'>Responsabilidad</h1>
                    <h3>La gestión de procesos eficientes</h3>
                </div>
            </Carousel.Item>
            <Carousel.Item className='vh-100'>
                <div className='position-absolute top-50 start-50 translate-middle text-white text-center headerCaption'>
                    <h1 className='text-uppercase text-white'>Profesionalismo</h1>
                    <h3>La garantía de nuestros servicios</h3>
                </div>
            </Carousel.Item>
        </Carousel> 
    </div>
  )
}

export default Header