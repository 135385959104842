import { useState } from 'react'
import axios from 'axios';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast';
import Iframe from 'react-iframe'
import './Contacto.css'



const Contacto = ({Anchor}) => {

  const [formState, setFormState] = useState({})
  const [validation, setValidation] = useState({})

  const API_PATH = 'https://www.biotecnologiasur.com.uy/api/contact/index.php';


  const showAlert = (type, textColor, msg) => {
    return <Toast className='mt-3 mx-3 rounded-0 border-0 text-center' bg={type}><Toast.Body className={textColor}>
    {msg}
  </Toast.Body></Toast>
  }

  const changeHandler = (event) =>{
    setFormState({...formState, [event.target.name]: event.target.value})
  }

  const validationsHandler = () => {
    if(!formState.nombre || !formState.email || !formState.asunto || !formState.mensaje){
      setValidation({validate:false, msg:"Debe completar todos los campos"})
      return {validate:false, msg:"Debe completar todos los campos"}
    }else{
      if(!formState.email.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        setValidation({validate:false, msg:"Email invalido"})
        return {validate:false, msg:"Email invalido"}
      }
      else{
        setFormState({mailSent: false, error:null})
        setValidation({validate:true})
        return {validate:true}
      }
    }
  }

  const submitHandler = (event) => {
    event.preventDefault()
    validationsHandler().validate === true &&
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: formState
    }).then(result => {
        setFormState({mailSent:result.data.sent, msg:result.data.message})
        console.log(result);
      }).catch(error => {
        setFormState({mailSent: false, error:`${error.message} - Inténtelo nuevamente.`})
      });
      
  }

  return (
    <>
    <div className='anchor' id={Anchor}></div>
    <section className='content bg02' fluid>
          <Container>
            <Row>
                <h1 className='text-white text-center text-md-start'>Contacto</h1>
            </Row>
            <Row>
              <Col className='col-12 mb-5 mb-xl-0 col-xl-9 col-xxl-8'>
                <div className='d-flex flex-sm-column flex-lg-row align-items-lg-center flex-wrap contact-card'>
                  <Iframe
                    url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.10729249745!2d-56.16641708433291!3d-34.90375688038282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f81a933bae3c3%3A0x23fcceb13847a65c!2sBv.%20Gral.%20Artigas%201345%2C%2011200%20Montevideo%2C%20Departamento%20de%20Montevideo%2C%20Uruguay!5e0!3m2!1ses-419!2sar!4v1663847588115!5m2!1ses-419!2sar"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade" 
                  />
                  <div className='p-3 p-sm-5'>
                    <p className='contact-card__infotitle'>Dirección</p>
                    <p>Bulevar Gral. Artigas 1345<br></br>Montevideo, Uruguay</p>
                    <p className='contact-card__infotitle'>Telefax</p>
                    <p>+598-24091188</p>
                    <p className='contact-card__infotitle'>Celular</p>
                    <p>+598-91047350</p>
                    <p className='contact-card__infotitle'>Email</p>
                    <a className='text-break' href="mailto:administracion@biotecnologiasur.com.uy">administracion@biotecnologiasur.com.uy</a>
                    <div className='contact-socials mt-4'>
                      <a href="https://wa.me/+59891047350" target="_blank" className='me-3'><i class="bi bi-whatsapp"></i></a>
                      <a href="https://www.instagram.com/biotecnologiasur/" target="_blank"><i class="bi bi-instagram"></i></a>
                    </div>
                  </div>
                </div>
                
              </Col>
              <Col className='col-12 col-xl-3 col-xxl-4'>
                <Form onSubmit={submitHandler} id="contactForm">
                  <Form.Group className="mb-3">
                    <Form.Control type="text" className='border-0 rounded-0' id="nombre" name="nombre" placeholder="Nombre"
                    value={formState.nombre || ''}
                    onChange={changeHandler}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" className='border-0 rounded-0' id="email" name="email" placeholder="Email"
                    value={formState.email || ''}
                    onChange={changeHandler}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="text" className='border-0 rounded-0' id="asunto" name="asunto" placeholder="Asunto"
                    value={formState.asunto || ''}
                    onChange={changeHandler}/>
                  </Form.Group>
                  <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    id="mensaje"
                    name="mensaje"
                    placeholder="Mensaje"
                    style={{ height: '215px' }}
                    className='border-0 rounded-0'
                    value={formState.mensaje || ''}
                    onChange={changeHandler}
                  />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="btnSubmit rounded-0 w-100 border-0">
                    Enviar
                  </Button>
                </Form>
              </Col>
              <Col className='alertCol'>
                <div className='d-flex justify-content-center align-items-center'>
                {
                  validation.validate === false && showAlert('info', 'text-dark', validation.msg)
                }
                {
                  formState.error && showAlert('danger', 'text-white', formState.error)
                }
                {
                  formState.mailSent && showAlert('success', 'text-white', formState.msg)       
                }
                </div>
              </Col> 
            </Row> 
        </Container>
    </section>
    </>
  )
}
export default Contacto